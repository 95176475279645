import React , {Component} from 'react';
let styles_responsive_image = {
     
  height: '30px',
  width: 'auto'
};
class Header extends Component {  
      
  
      render() {
        
        return (
          <nav className="navbar navbar-light bg-light">
            <a className="navbar-brand" href="/">
              <img src="/logo_talisis.png" style={styles_responsive_image} className="d-inline-block align-top " alt=""></img>
            </a>
        </nav>
        );
      }
}

export default Header;