import React , {Component} from 'react';
let styles = {
     
  paddingTop: '75px',
  width: '100%',
  textAlign:'center'
};
class Loader extends Component {  
      render() {
        return (
            <div style={styles}>
            <img src="/loading.svg" alt="Cargando..." ></img>
          </div>
        );
      }
}

export default Loader;