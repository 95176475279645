import React , {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class MultiItemString extends Component {

  render() {
    return (
      this.props.items.map((item, idx)=> {
        return (
          <div key={idx}>
            <div className="input-group input-group-sm mb-1">
              <div className="input-group-prepend">
                <button 
                    className="btn btn-danger" 
                    type="button" 
                    onClick={this.props.handleRemove(idx)}
                  ><FontAwesomeIcon icon="trash" /></button>
              </div>
              <input name={this.props.form_name}
                className={`form-control ${ item.error ? 'is-invalid' : ''}`}
                id={'txt_'+this.props.form_name}
                placeholder=''
                type="text"
                value={item.value}
                onChange={this.props.handleChange(idx)}
                onBlur={this.props.handleChange(idx)} required></input>
                <div className='invalid-feedback'> No debe estar vacío</div>
            </div>
            

          </div>
          
        );
      })
    );
  }
}

export default MultiItemString;