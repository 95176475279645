import React , {Component} from 'react';

class Title extends Component {  
      render() {
        const size = this.props.size;
        var title_html = "";
        switch (size){
          case "2":
              title_html = <h2>{this.props.title}</h2>
            break;
          
          case "3":
              title_html = <h3>{this.props.title}</h3>
            break;
        
          case "4":
              title_html = <h4>{this.props.title}</h4>
            break;
      
          case "5":
              title_html = <h5>{this.props.title}</h5>
            break;
          default:
              title_html = <h1>{this.props.title}</h1>
            break;
        }
        return (
          <div className="title-component">
            <div className={ `caption ${size === '1' ? '' : 'highligth-color'}`} >{ this.props.subtitle}</div>
            {title_html}
          </div>
        );
      }
}

export default Title