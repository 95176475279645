import React from 'react';
import { Route, HashRouter, NavLink, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import StepOne from './pages/profile_wizard/step_1/StepOne';
import StepTwo from './pages/profile_wizard/step_2/StepTwo';
import StepThree from './pages/profile_wizard/step_3/StepThree';
import StepSix from './pages/profile_wizard/step_6/StepSix';
import WizardHome from './pages/profile_wizard/home/Home';
import WizardConfirm from './pages/profile_wizard/confirm/Confirm';
import Header from './componenets/Header/Header';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from './componenets/Loader/Loader';
import './App.css';
import Title from "./componenets/Title/Title";
import GetEmployee from './componenets/GetEmployee/GetEmployee';

const USER_SERVICE_URL = process.env.REACT_APP_API_URL + 'employee/';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      employeeId: '',
      employee: [],
      isLoading: true,
      completeness:0,
      steps:{
        1:false,
        2:false,
        3:false,
        4:false
      }
      
    }
    library.add(fas);
    
        
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleGetEmployee = this.handleGetEmployee.bind(this);
  }
  componentDidMount() {
    this.fetchEmployee();
  }

  fetchEmployee() {
    var id= 0;
    if(this.state.employeeId){
      id = this.state.employeeId;
      
      // Where we're fetching data from
    fetch(USER_SERVICE_URL+id+'/completeness')
    // We get the API response and receive data in JSON format...
    .then(response => response.json())
    // ...then we update the users state
    .then(data =>{

      console.log(data.employee);

      if(data.employee === undefined || data.employee.id === undefined){
        this.setState({
          error: 'error',
          isLoading: false,
        });
      }else{
        this.setState({
          employee: data.employee,
          completeness: parseInt(data.completeness),
          steps: data.steps,
          isLoading: false,
        });
      }
      
    }
    )
    // Catch any errors we hit and update the app
    .catch(error => this.setState({ error, isLoading: false }));
    }
    this.setState({
      isLoading: false,
    });
  }
  handleUpdate(){
    
    this.fetchEmployee();
  }

  handleGetEmployee(id){
    
    
    
    this.setState({
      employeeId: id,
      isLoading: true
    },()=>{
      this.fetchEmployee();
    });
  }
  render(){
    
    if(this.state.isLoading){
      return (
        <div>
          <Header />
          <Loader />
        </div>
      );
    }else if(!this.state.employee){
      return (
        <div>
          <Header />
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-8 col-lg-6">
                <div className="header-wrap">
                  <Title  size="1" title={"Convencion 2019"} subtitle="Registro " />
                </div>
              </div>
            </div>
        <div className="contentWrap boxed-wrap" >
      <div className="row">
        <div className="col-12">
        <h3>No se encontro el usuario</h3>
        </div>

      </div>
        </div>
        </div>
        </div>
      );
    }else{
      return (
        <HashRouter>
        <Header />
        <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-8 col-lg-6">
                <div className="header-wrap">
                  <Title  size="1" title={"Convencion 2019"} subtitle="Registro " />
                </div>
              </div>
            </div>
        <div className="contentWrap boxed-wrap" >
          <div className="sidebar">
            <ul>
              <li className={this.state.steps[1]? 'done':''}>
                <NavLink to="/datos-generales" activeClassName="active">
                  <FontAwesomeIcon icon="check-circle" />
                  <FontAwesomeIcon icon="circle" />
                  <span className="caption step">&nbsp;&nbsp;1&nbsp;</span><span className="caption title">Datos Generales</span>
                </NavLink>
                </li>
              
              <li className={this.state.steps[2]? 'done':''}>
                <NavLink to="/contacto" activeClassName="active">
                  <FontAwesomeIcon icon="check-circle" />
                  <FontAwesomeIcon icon="circle" /><span className="caption step">&nbsp;&nbsp;2&nbsp;</span><span className="caption title">Contacto</span>
                </NavLink>
              </li>
              
              <li  className={this.state.steps[3]? 'done':''} >
                <NavLink to="/salud" activeClassName="active">
                  <FontAwesomeIcon icon="check-circle" />
                  <FontAwesomeIcon icon="circle" />
                  <span className="caption step">&nbsp;&nbsp;3&nbsp;</span><span className="caption title">Salud</span>
                </NavLink>
              </li>
              
              
              <li className={this.state.steps[4]? 'done':''} >
                <NavLink to="/eventos" activeClassName="active">
                  <FontAwesomeIcon icon="check-circle" />
                  <FontAwesomeIcon icon="circle" />
                  <span className="caption step">&nbsp;&nbsp;4&nbsp;</span><span className="caption title">Otros Eventos</span>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="content">
            
          <Switch>
            
                <Route path="/email/:id"
                  render={(props) => <GetEmployee {...props} updateMethod={this.handleGetEmployee} /> }
                  />
                <Route exact path="/" 
                render={(props) => <WizardHome {...props} employee={this.state.employee} />}
                />
                <Route path="/datos-generales" render={(props)=>
                  <StepOne  {...props} employee={this.state.employee}  updateMethod={this.handleUpdate} />
                }/>
                <Route path="/contacto" render={(props)=>
                  <StepTwo {...props} employee={this.state.employee}   updateMethod={this.handleUpdate}  />
                }/>
                
                <Route path="/salud" render={(props)=>
                  <StepThree {...props} employee={this.state.employee}  updateMethod={this.handleUpdate}  />
                }/>
                
                <Route path="/eventos" render={(props)=>
                  <StepSix {...props} employee={this.state.employee} updateMethod={this.handleUpdate}  />
                }/>
                <Route exact path="/confirmacion" 
                render={(props) => <WizardConfirm {...props} employee={this.state.employee} />}
                />
            </Switch>
            </div>
          
        </div>         
        
        <br></br>
        </div>
        
        </HashRouter>
    );
    }
};

}
export default App;