import React from 'react';
import Title from "../../../componenets/Title/Title";
import { Redirect } from "react-router-dom";

class Home extends React.Component {
    render() {
      
      if(this.props.employee.participacion_eventos && this.props.employee.participacion_eventos.convencion2019 === true){
        return <Redirect to="confirmacion" />
      }

      return (
        <div className="row">
          <div className="col-12">
            <Title  size="2" title={"Hola "+this.props.employee.nombre} subtitle="" />
            <br></br>
            
                <p >Bienvenido al registro para la convención 2019. Completa toda la información solicitada y al terminar te llegara un correo de confirmación.</p>
                
            <br></br>
            <div className="cmd-holder">
              <a href="#/datos-generales"  className="btn btn-primary" >Comenzar</a>
            </div>
          </div>
        </div>
      );
    }
  }
export default Home;