import React from 'react';
import Title from "../../../componenets/Title/Title";
import ContactForm from "../../../componenets/Profile/ContactForm/ContactForm";

class StepTwo extends React.Component {
    render() {
      return (
        <div>
          
          <Title  size="3" title="Contacto" subtitle="2 de 4" />
          <br></br>
            <ContactForm employeeId={this.props.employee.id} employee={this.props.employee} updateMethod={this.props.updateMethod } />
        </div>
      );
    }
  }

export default StepTwo;