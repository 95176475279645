import React from 'react';
import Title from "../../../componenets/Title/Title";
import HealthForm from "../../../componenets/Profile/HealthForm/HealthForm";

class StepThree extends React.Component { 
    render() {
      return (
        <div>    
          <Title  size="3" title="Salud" subtitle="3 de 4" />
          <br></br>
          <HealthForm employeeId={this.props.employee.id} employee={this.props.employee} updateMethod={this.props.updateMethod } />
        </div>
      );
    }
  }

export default StepThree;