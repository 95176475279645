import React , {Component} from 'react';
import axios from 'axios';
import Loader from '../../Loader/Loader';
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker, {registerLocale} from 'react-datepicker';
import es from 'date-fns/locale/es';

import "react-datepicker/dist/react-datepicker.css";

registerLocale('es', es);
const USER_SERVICE_URL = process.env.REACT_APP_API_URL + 'employee/';

class ProfileGeneralForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          employee: this.props.employee,
          isLoading: true,
          submittedSuccesfully:false,
          isMexican:true,
          form:{
              nombre: {
                value:'',
                error:false,
                error_message:'El nombre debe de ser de más de 2 caracteres',
                form_name:'nombre'
              },
              segundo_nombre: {
                value:'',
                error:false,
                error_message:'El segundo nombre debe de ser de más de 2 caracteres',
                form_name:'segundo_nombre'
              },
              apellido_paterno: {
                value:'',
                error:false,
                error_message:'El apellido paterno debe de ser de más de 2 caracteres',
                form_name:'apellido_paterno'
              },
              apellido_materno: {
                value:'',
                error:false,
                error_message:'El apellido materno debe de ser de más de 2 caracteres',
                form_name:'apellido_materno'
              },
              talla_camisa: {
                value:'',
                error:false,
                error_message:'Selecciona una opción',
                form_name:'talla_camisa'
              },
              apodo: {
                value:'',
                error:false,
                error_message:'Debe de ser de más de 2 caracteres',
                form_name:'apodo'
              },
              genero: {
                value:'',
                error:false,
                error_message:'Selecciona una opción',
                form_name:'genero'
              },              
              fecha_de_nacimiento: {
                value:'',
                error:false,
                error_message:'Ingresa fecha de nacimiento valida',
                form_name:'fecha_de_nacimiento'
              }
          }
          
        };
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    
      
      componentDidMount() {
        this.preFillFormData();
      }
      handleChangeSelectNationality = event =>{
        var {form, employee} = this.state;
        const value = event.value;
        const form_name = "nacionalidad";
        form[form_name].value = value;
        if(value !== "MX"){
         form.rfc.value = "";
          form.curp.value = "";
          this.setState({ isMexican: false });
        }else{
          form.rfc.value = employee.rfc;
         form.curp.value = employee.curp;
          this.setState({ isMexican: true });
        }

        this.setState({ form: form }, () => {
          this.validate(form_name);
        });
      }
      
      handleChangeBirthday = date =>{
        var {form} = this.state;
        const form_name = "fecha_de_nacimiento";
        form[form_name].value = date;
        this.setState({ form: form }, () => {
          this.validate(form_name);
        });
      }

      handleChange = event => {
        var {form} = this.state;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const form_name = target.name;
        form[form_name].value = value;
        this.setState({ form: form }, () => {
          //console.log(this.state.form)
          this.validate(form_name);
        });
      };

      validateHandle = event =>{

      };

      validate(form_name){
        const { form } = this.state;
        const  current_value = form[form_name].value;
        var has_error = false;

        switch(form_name){
          case "nombre":
          case "apellido_paterno":
          case "apellido_materno":      
              if(current_value === undefined ||  current_value.length < 3){
                has_error = true;                
              }
            break;
          case "segundo_nombre":       
              if(current_value !== undefined && current_value.length > 0){
                if(current_value.length < 3){
                  has_error = true;
                }
              }
            break;          
          case "rfc":
            if(current_value !== undefined && current_value.length > 0){
              if( current_value.length < 12 || current_value.length > 13 ){
                has_error = true;
              }
            }
            break;
          case "curp":
            
            if(current_value !== undefined && current_value.length > 0){
              var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        validado = current_value.match(re);
              if( !validado ){
                has_error = true;
              }
            }
              break;
          default:
            break;
        }
        
        form[form_name].error = has_error;
        this.setState({
          form: form
        });
      }

      validateAll(){
        const {form} = this.state;
        for (var key in form){
          this.validate(key);
        }
        return true;
      }

      isFormValid(){
        this.validateAll();
        const {form} = this.state;
        for (var key in form){
          if(form[key].error)
            return false;
        }
        return true;
      }

      getFormJsonData(){
        const {form} = this.state;
        var json_data = {};
        for (var key in form){
          if(form[key].value !== undefined && form[key].value !==''){
            json_data['' + form[key].form_name] = form[key].value;
          }else{
            json_data['' + form[key].form_name] = '';
          }
        };
        json_data = JSON.stringify(json_data);
        console.log(json_data);
        return json_data;
      }

      preFillFormData(){
        const {form, employee} = this.state;
        for (var key in form){
          form[key].value = employee[form[key].form_name];
        }
        
        var birthday_default_value = new Date();
        if(!form.fecha_de_nacimiento.value){
          birthday_default_value.setFullYear(birthday_default_value.getFullYear() - 25);
        }else{
          birthday_default_value = new Date(''+form.fecha_de_nacimiento.value);
        }
        form.fecha_de_nacimiento.value = birthday_default_value;

        
        if(!form.apodo.value){
          form.apodo.value = employee.nombre+' '+employee.apellido_paterno;
        }

        //Special settings
        
        
        this.setState({ 
          isLoading: false,
          form: form 
        }, () => {
          //this.validateAll();
        });
      }

      handleSubmit = event => {
        event.preventDefault();
        if( this.isFormValid() ){
          var json_data = this.getFormJsonData();
          this.setState({
            isLoading: true,
          });
          const th = this;
          var options ={
            method: 'post',
            url:USER_SERVICE_URL+this.props.employeeId,
            data: json_data,
            headers: {
            'Content-Type': 'application/json'
            }
          }
          axios( options).then(async()=> {
            await th.props.updateMethod();
            th.setState({
              isLoading: false,
              submittedSuccesfully:true
            });
          })
          .then(function (response) { 
            //this.props.updateMethod();
            //this.props.history.push('/path')
            
          })
          .catch(function (error) {
            th.setState({
              isLoading: false,
            });
            alert('Error al guardar');
          });
        }else{
          
        }     
      };
    
      render() {
        
        if(this.props.employee.participacion_eventos && this.props.employee.participacion_eventos.convencion2019 === true){
          return <Redirect to="confirmacion" />
        }

        if(this.state.submittedSuccesfully){
          return <Redirect to="contacto" />
        }
        if(this.state.isLoading){
          return (
            
          <Loader />
          )
        }else{
          return (
            <form onSubmit={this.handleSubmit} className="form-profile" >
              <div className="row">
                <div className="col-xs-12">

                </div>
                <div className="col-xs-12">

                </div>
              </div>

              <div className="row">
                <div className="col-xs-12  col-md-6 col-lg-3">
                  <div className='form-group'>
                    <label className="caption" htmlFor={'txt_'+this.state.form.nombre.form_name}>Nombre</label>
                    <input name={this.state.form.nombre.form_name}
                        className={`form-control ${this.state.form.nombre.error ? 'is-invalid' : ''}`}
                        id={'txt_'+this.state.form.nombre.form_name}
                        placeholder='Primer Nombre'
                        value={this.state.form.nombre.value}
                        onChange={this.handleChange}
                        onBlur={this.handleChange} required></input>
                    <div className='invalid-feedback'>{this.state.form.nombre.error_message}</div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-3">
                  <div className='form-group'>
                    <label className="caption" htmlFor={'txt_'+this.state.form.segundo_nombre.form_name}>Segundo Nombre</label>
                    <input name={this.state.form.segundo_nombre.form_name}
                        className={`form-control ${this.state.form.segundo_nombre.error ? 'is-invalid' : ''}`}
                        id={'txt_'+this.state.form.segundo_nombre.form_name}
                        placeholder='Primer Nombre'
                        value={this.state.form.segundo_nombre.value}
                        onChange={this.handleChange}
                        onBlur={this.handleChange}></input>
                    <div className='invalid-feedback'>{this.state.form.segundo_nombre.error_message}</div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-3">
                <div className='form-group'>
                <label className="caption" htmlFor={'txt_'+this.state.form.apellido_paterno.form_name}>Apellido Paterno</label>
                <input name={this.state.form.apellido_paterno.form_name}
                    className={`form-control ${this.state.form.apellido_paterno.error ? 'is-invalid' : ''}`}
                    id={'txt_'+this.state.form.apellido_paterno.form_name}
                    placeholder='Apellido Paterno'
                    value={this.state.form.apellido_paterno.value}
                    onChange={this.handleChange}
                    onBlur={this.handleChange} ></input>
                <div className='invalid-feedback'>{this.state.form.apellido_paterno.error_message}</div>
              </div>
              
                </div>
                <div className="col-xs-12  col-md-6 col-lg-3">

                <div className='form-group'>
                <label className="caption" htmlFor={'txt_'+this.state.form.apellido_materno.form_name}>Apellido Materno</label>
                <input name={this.state.form.apellido_materno.form_name}
                    className={`form-control ${this.state.form.apellido_materno.error ? 'is-invalid' : ''}`}
                    id={'txt_'+this.state.form.apellido_materno.form_name}
                    placeholder='Apellido Materno'
                    value={this.state.form.apellido_materno.value}
                    onChange={this.handleChange}
                    onBlur={this.handleChange} ></input>
                <div className='invalid-feedback'>{this.state.form.apellido_materno.error_message}</div>
              </div>

                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className='form-group'>
                    <label className="caption" htmlFor={'txt_'+this.state.form.apodo.form_name}>Nombre en Gafete</label>
                    <input name={this.state.form.apodo.form_name}
                        className={`form-control ${this.state.form.apodo.error ? 'is-invalid' : ''}`}
                        id={'txt_'+this.state.form.apodo.form_name}
                        placeholder='Mi Apodo'
                        value={this.state.form.apodo.value}
                        onChange={this.handleChange}
                        onBlur={this.handleChange} required></input>
                    <div className='invalid-feedback'>{this.state.form.apodo.error_message}</div>
                  </div>
                </div>
                
                <div className="col-12 col-md-6">
                  <div className='form-group'>
                    <label className="caption" htmlFor={'combo_'+this.state.form.talla_camisa.form_name}>Talla de Camisa</label>
                    <select name={this.state.form.talla_camisa.form_name}
                        className={`form-control ${this.state.form.talla_camisa.error ? 'is-invalid' : ''}`}
                        id={'combo_'+this.state.form.talla_camisa.form_name}
                        value={this.state.form.talla_camisa.value}
                        onChange={this.handleChange}
                        onBlur={this.handleChange}>
                          <option value="s" >Chica</option>
                          <option value="m">Mediana</option>
                          <option value="l">Grande</option>
                          <option value="xl">Extra Grande</option>
                        </select>
                    <div className='invalid-feedback'>{this.state.form.talla_camisa.error_message}</div>
                  </div>
                </div>
                
                

              </div>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <div className='form-group'>
                    <label className="caption" htmlFor={'combo_'+this.state.form.genero.form_name}>Genero</label>
                    <select name={this.state.form.genero.form_name}
                        className={`form-control ${this.state.form.genero.error ? 'is-invalid' : ''}`}
                        id={'combo_'+this.state.form.genero.form_name}
                        value={this.state.form.genero.value}
                        onChange={this.handleChange}
                        onBlur={this.handleChange}>
                          <option value="masculino" >Masculino</option>
                          <option value="femenino">Femenino</option>
                        </select>
                    <div className='invalid-feedback'>{this.state.form.genero.error_message}</div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">

                  
    <div className='form-group'>
                    <label className="caption" htmlFor={'date_'+this.state.form.fecha_de_nacimiento.form_name}>Fecha de Nacimiento</label>
                        <div>
                          <DatePicker
                              selected ={this.state.form.fecha_de_nacimiento.value}
                              onChange={ this.handleChangeBirthday }
                              className={`form-control ${this.state.form.fecha_de_nacimiento.error ? 'is-invalid' : ''}`}
                              locale="es"
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={150}
                              maxDate={new Date()}
                            withPortal
                            />
                        </div>
                    <div className='invalid-feedback'>{this.state.form.genero.error_message}</div>
                    <div className='caption'><small><FontAwesomeIcon icon="info-circle" />&nbsp; Mes /  Dia / Año </small></div>
                  
                  </div>
                </div>
              </div>
            
              
              <div className="cmd-holder">
                <a href="#/" className="btn btn-link" >Cancelar</a>&nbsp;
                <button  className="btn btn-outline-dark" >Siguiente</button>
              </div>
            </form>
          
          );
        }
        }
        
}

export default ProfileGeneralForm