import React , {Component} from 'react';
import axios from 'axios';
import Loader from '../../Loader/Loader';
import { Redirect } from "react-router-dom";

const USER_SERVICE_URL = process.env.REACT_APP_API_URL + 'employee/';

class InterestForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          employee: this.props.employee,
          isLoading: true,
          submittedSuccesfully:false,
          form:{
            convencion2018: {
                value:false,
                error:false,
                error_message:'El nombre debe de ser de más de 2 caracteres',
                form_name:'convencion2018'
              },
              connection2019_1: {
                value:false,
                error:false,
                error_message:'El segundo nombre debe de ser de más de 2 caracteres',
                form_name:'connection2019_1'
              }
              ,
              connection2019_2:{
                value:false,
                error:false,
                error_message:'El segundo nombre debe de ser de más de 2 caracteres',
                form_name:'connection2019_2'
              }
          }
          
        };
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    
      
      componentDidMount() {
        this.preFillFormData();
      }

      handleChange = event => {
        var {form} = this.state;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const form_name = target.name;
        form[form_name].value = value;
        this.setState({ form: form }, () => {
          //console.log(this.state.form)
          this.validate(form_name);
        });
      };

      validate(form_name){
        const { form } = this.state;
       // const  current_value = form[form_name].value;
        var has_error = false;
        form[form_name].error = has_error;
        this.setState({
          form: form
        });
      }

      validateAll(){
        const {form} = this.state;
        for (var key in form){
          this.validate(key);
        }
        return true;
      }

      isFormValid(){
        this.validateAll();
        const {form} = this.state;
        for (var key in form){
          if(form[key].error)
            return false;
        }
        return true;
      }

      getFormJsonData(){
        const {form} = this.state;
        var json_data = {};
        for (var key in form){
          if(form[key].value !== undefined && form[key].value !==''){
            json_data['' + form[key].form_name] = form[key].value;
          }else{
            if(form[key].form_name === 'otro'){
              //json_data['' + form[key].form_name] = "";
            }else{
              json_data['' + form[key].form_name] = false;
            }
          }
        };
        json_data['convencion2019'] = true;
        json_data = JSON.stringify({participacion_eventos:json_data});
        console.log(json_data);
        return json_data;
      }

      preFillFormData(){
        const {form, employee} = this.state;
        for (var key in form){
          var default_value = false;
          if(employee.participacion_eventos && employee.participacion_eventos[form[key].form_name] && employee.participacion_eventos[form[key].form_name] === true){
            default_value = true;
          }
          form[key].value = default_value;
        }
        this.setState({ 
          isLoading: false,
          form: form 
        }, () => {
          //this.validateAll();
        });
      }

      handleSubmit = event => {
        event.preventDefault();
        if( this.isFormValid() ){
          var json_data = this.getFormJsonData();
          this.setState({
            isLoading: true,
          });
          const th = this;
          var options ={
            method: 'post',
            url:USER_SERVICE_URL+this.props.employeeId,
            data: json_data,
            headers: {
            'Content-Type': 'application/json'
            }
          }
          axios( options).then(async()=> {
            await th.props.updateMethod();
            th.setState({
              isLoading: false,
              submittedSuccesfully:true
            });
          })
          .then(function (response) { 
            //this.props.updateMethod();
            //this.props.history.push('/path')
            
          })
          .catch(function (error) {
            th.setState({
              isLoading: false,
            });
            alert('Error al guardar');
          });
        }else{
          
        }     
      };
    
      render() {
        if(this.props.employee.participacion_eventos && this.props.employee.participacion_eventos.convencion2019 === true){
          return <Redirect to="confirmacion" />
        }
        if(this.state.submittedSuccesfully){
          return <Redirect to="confirmacion" />
        }
        if(this.state.isLoading){
          return (
            
          <Loader />
          )
        }else{
          return (
            <form onSubmit={this.handleSubmit} className="form-profile" >

              <div className="row">
                <div className="col-12">
                  <div className='form-group'>
                    <div className="form-check">
                    <input 
                        className="form-check-input" 
                        type="checkbox"
                        name={ this.state.form.convencion2018.form_name }
                        onChange={this.handleChange }
                        checked={ this.state.form.convencion2018.value }
                        id={'cBox_'+this.state.form.convencion2018.form_name}></input>
                    <label className="form-check-label" htmlFor={'cBox_'+this.state.form.convencion2018.form_name}>
                      Convención 2018
                    </label>
                  </div>
                  <div className="form-check">
                    <input 
                        className="form-check-input" 
                        type="checkbox"
                        name={ this.state.form.connection2019_1.form_name }
                        onChange={this.handleChange }
                        checked={this.state.form.connection2019_1.value}
                        id={'cBox_'+this.state.form.connection2019_1.form_name}></input>
                    <label className="form-check-label" htmlFor={'cBox_'+this.state.form.connection2019_1.form_name}>
                      Connection Febrero 2019 
                    </label>
                  </div>
                  <div className="form-check">
                    <input 
                        className="form-check-input" 
                        type="checkbox"
                        name={ this.state.form.connection2019_2.form_name }
                        onChange={this.handleChange }
                        checked={this.state.form.connection2019_2.value}
                        id={'cBox_'+this.state.form.connection2019_2.form_name}></input>
                    <label className="form-check-label" htmlFor={'cBox_'+this.state.form.connection2019_2.form_name}>
                      Connection Julio 2019
                    </label>
                  </div>                 
                  
                  </div>
                </div>
              </div>
              
              <div className="cmd-holder">
                <a href="#/" className="btn btn-link" >Cancelar</a>&nbsp;
                <button  className="btn  btn-outline-dark" >Terminar Registro</button>
              </div>
            </form>
          
          );
        }
        }
        
}

export default InterestForm