import React , {Component} from 'react';
import { Redirect } from "react-router-dom";

class GetEmployee extends Component { 
  constructor(props) {
    super(props);
  
    
    
  
  }

   componentDidMount () {
    const { id } = this.props.match.params
    this.props.updateMethod(id);
  }
  
  render() {
    
    return <Redirect to="/" />; //(<div>Hello {this.props.match.params.id}</div>); //
  }
}

export default GetEmployee;