import React , {Component} from 'react';
import axios from 'axios';
import Loader from '../../Loader/Loader';
import { Redirect } from "react-router-dom";
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "react-datepicker/dist/react-datepicker.css";
import MultiItemString from '../../MultiItemString/MultiItemString';

const USER_SERVICE_URL = process.env.REACT_APP_API_URL + 'employee/';
var tipoSangreDefaultValue = {};
const optionsTipoSangre =[
  { value: 'O+',label:'O+'},
  { value: 'O-',label:'O-'},
  { value: 'A+',label:'A+'},
  { value: 'A-',label:'A-'},
  { value: 'B+',label:'B+'},
  { value: 'B-',label:'B-'},
  { value: 'AB+',label:'AB+'},
  { value: 'AB-',label:'AB-'}
];

class HealthForm extends Component {
  constructor(props) {
      super(props);
      this.state = {
        employee: this.props.employee,
        isLoading: true,
        submittedSuccesfully:false,
        isMexican:true,
        form:{
          preferencia_alimenticia: {
              value:'',
              error:false,
              error_message:'Debe mayor a 2 caracteres',
              form_name:'preferencia_alimenticia',
              format:'string'
            },
          
            alergias: {
              value:[],
              error:false,
              error_message:'No pueden estar vaicas',
              form_name:'alergias',
              format:'string'
            },
            padecimientos: {
              value:[],
              error:false,
              error_message:'No pueden estar vaicas',
              form_name:'padecimientos',
              format:'string'
            },
            medicamentos: {
              value:[],
              error:false,
              error_message:'No pueden estar vaicas',
              form_name:'medicamentos',
              format:'string'
            },
            tipo_sangre: {
              value:'',
              error:false,
              error_message:'Seleciona una opción',
              form_name:'tipo_sangre',
              format:'string'
            }
        }
        
      };
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChangeAllergies = this.handleChangeAllergies.bind(this);
  }
    
  componentDidMount() {
    this.preFillFormData();
  }

  handleChangeSelectTipoDeSangre = event =>{
    var {form} = this.state;
    const value = event.value;
    const form_name = "tipo_sangre";
    form[form_name].value = value;
    this.setState({ form: form }, () => {
      this.validate(form_name);
    });
  }
  
  handleChangeAllergies = id => event =>{
    var {form} = this.state;
    form.alergias.value[id].value = event.target.value;
    this.setState({ form: form }, () => {
      //console.log(form.alergias.value)
      this.validate('alergias');
    });
  }

  handleRemoveAllergies =id => event=>{
    var {form} = this.state;
    if(form.alergias.value.length === 1){
      form.alergias.value = [];
    }else
      form.alergias.value.splice(id, 1);
    this.setState({ form: form }, () => {
      //console.log(form.alergias.value)
      this.validate('alergias');
    });
  }

  handleChangeMedicamentos = id => event =>{
    var {form} = this.state;
    form.medicamentos.value[id].value = event.target.value;
    this.setState({ form: form }, () => {
      //console.log(form.alergias.value)
      this.validate('medicamentos');
    });
  }

  handleRemoveMedicamentos =id => event=>{
    var {form} = this.state;
    if(form.medicamentos.value.length === 1){
      form.medicamentos.value = [];
    }else
      form.medicamentos.value.splice(id, 1);
    this.setState({ form: form }, () => {
      //console.log(form.alergias.value)
      this.validate('medicamentos');
    });
  }

  handleChangePadecimientos = id => event =>{
    var {form} = this.state;
    form.padecimientos.value[id].value = event.target.value;
    this.setState({ form: form }, () => {
      this.validate('padecimientos');
    });
  }

  handleRemovePadecimientos =id => event=>{
    var {form} = this.state;
    if(form.padecimientos.value.length === 1){
      form.padecimientos.value = [];
    }else
      form.padecimientos.value.splice(id, 1);
    this.setState({ form: form }, () => {
      this.validate('padecimientos');
    });
  }

  handleChange = event => {
    var {form} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const form_name = target.name;
    form[form_name].value = value;
    this.setState({ form: form }, () => {
      this.validate(form_name);
    });
  };

  addMultiStringItem = (form_name)=>(e) => {
    var {form} = this.state;        
    form[form_name].value.push( {value:"", error:false});
    this.setState((prevState) => ({
      form: form
    }));
  }

  validate(form_name){
    const { form } = this.state;
    const  current_value = form[form_name].value;
    var has_error = false;
    switch(form_name){
      case "preferencia_alimenticia":
        if(current_value !== undefined && current_value.length > 0){
          if(current_value.length < 3){
            has_error = true;
          }
        }
        break;
      case "tipo_sangre":     
        if(current_value === undefined ||  current_value.length < 1){
          form[form_name].error = true;              
        }
      break;
      case "alergias":
      case "medicamentos":
      case "padecimientos":
        for(var key in current_value){
          if(!current_value[key].value )
            form[form_name].value[key].error = true;
          else 
            form[form_name].value[key].error = false;
        }
        break;
      default:
        break;
    }
    
    
    this.setState({
      form: form
    });
  }

  validateAll(){
    const {form} = this.state;
    for (var key in form){
      this.validate(key);
    }
    return true;
  }

  isFormValid(){
    this.validateAll();
    const {form} = this.state;
    for (var key in form){
      if(form[key].error)
        return false;
    }
    return true;
  }

  getFormJsonData(){
    const {form} = this.state;
    var json_data = {salud:{}};
    for (var key in form){
      if(key !== 'preferencia_alimenticia'){
        if(form[key].value !== undefined && form[key].value !==''){
          if(Array.isArray(form[key].value)){
            if(form[key].value.length > 0){
              json_data.salud['' + form[key].form_name] = [];
              for(var i of form[key].value){
                var new_value = ""+i.value;
                if(form[key].format === 'number')
                  new_value = parseInt(new_value);
  
                json_data.salud['' + form[key].form_name].push(new_value);
              }
            }
            
          }else{
            new_value = ""+form[key].value;
            if(form[key].format === 'number'){
              new_value = parseInt(new_value);
            }
            json_data.salud['' + form[key].form_name] = new_value;
          }
        }else{
          json_data.salud['' + form[key].form_name] = '';
        }
      }else{
        if(form[key].value !== undefined && form[key].value !==''){
          json_data['' + form[key].form_name] = form[key].value;
        }
        else
          json_data['' + form[key].form_name] = 'Ninguna';
      }
    };
    json_data = JSON.stringify(json_data);
    console.log(json_data);
    return json_data;
  }

  preFillFormData(){
    const {form, employee} = this.state;
    for (var key in form){
      if(key !== 'preferencia_alimenticia'){
        if(key === "alergias" || key === "padecimientos" || key === "medicamentos"){
          if(employee.salud){
            var current = employee.salud[key];
            if(current){  
              for (var item of current){
                form[key].value.push({value: ""+item, error:false }); 
              }
            }
          }
        }else{
          form[key].value = employee.salud[form[key].form_name];
        }
      }else{
        form[key].value = employee[form[key].form_name];
      }
    }
    /*
    //defaults
    if(!form.unidad_operativa.value){
      form.unidad_operativa.value = "TALISIS";
    }
    
    //defaults
    if(!form.departamento.value){
      form.departamento.value = options_unidad_operativa[0].value;
    }      
    
    */

    //Special settings
    if(form.tipo_sangre.value){
      tipoSangreDefaultValue = Object.keys(optionsTipoSangre).filter(function(x) {
        return optionsTipoSangre[x].value.indexOf(form.tipo_sangre.value) > -1;
      }).map(function(x) {
        return optionsTipoSangre[x];
      });
    }
    

    this.setState({ 
      isLoading: false,
      form: form 
    }, () => {
      
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    if( this.isFormValid() ){
      var json_data = this.getFormJsonData();
      this.setState({
        isLoading: true,
      });
      const th = this;
      var options ={
        method: 'post',
        url:USER_SERVICE_URL+this.props.employeeId,
        data: json_data,
        headers: {
        'Content-Type': 'application/json'
        }
      }
      axios( options).then(async()=> {
        await th.props.updateMethod();
        th.setState({
          isLoading: false,
          submittedSuccesfully:true
        });
      })
      .then(function (response) { 
        //this.props.updateMethod();
        //this.props.history.push('/path')
        
      })
      .catch(function (error) {
        th.setState({
          isLoading: false,
        });
        alert('Error al guardar');
        console.log(error);
      });
    }else{
      
    }     
  };

  render() {
    if(this.props.employee.participacion_eventos && this.props.employee.participacion_eventos.convencion2019 === true){
      return <Redirect to="confirmacion" />
    }
    if(this.state.submittedSuccesfully){
      return <Redirect to="eventos" />
    }
    if(this.state.isLoading){
      return (
        
      <Loader />
      )
    }else{
      return (                
        <form onSubmit={this.handleSubmit} className="form-profile" >
          <div className="row"><div className="col-xs-12"></div><div className="col-xs-12"></div></div>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className='form-group'>
            <label className="caption" htmlFor={'txt_'+this.state.form.preferencia_alimenticia.form_name}>Preferencia Alimenticia</label>
            <input name={this.state.form.preferencia_alimenticia.form_name}
                className={`form-control ${this.state.form.preferencia_alimenticia.error ? 'is-invalid' : ''}`}
                id={'txt_'+this.state.form.preferencia_alimenticia.form_name}
                placeholder='Intolerante a la Lactosa, Vegetariano, etc'
                value={this.state.form.preferencia_alimenticia.value}
                onChange={this.handleChange}
                onBlur={this.handleChange} ></input>
            <div className='invalid-feedback'>{this.state.form.preferencia_alimenticia.error_message}</div>
          </div>
            </div>
            <div className="col-12 col-md-6">
              
            <div className='form-group'>
                    <label className="caption" htmlFor={'ddl_'+this.state.form.tipo_sangre.form_name}>Tipo de Sangre</label>
                    <Select 
                      defaultValue={tipoSangreDefaultValue}
                      options={optionsTipoSangre}
                      placeholder='Selecciona una'
                      className={` ${this.state.form.tipo_sangre.error ? 'is-invalid' : ''}`}
                      onChange={this.handleChangeSelectTipoDeSangre}
                      name={this.state.form.tipo_sangre.form_name}
                      id={'ddl_'+this.state.form.tipo_sangre.form_name}
                      />
                    <div className='invalid-feedback'>{this.state.form.tipo_sangre.error_message}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div className='form-group'>
                <label className="caption" htmlFor={'txt_'+this.state.form.padecimientos.form_name}>Padecimientos</label>
                <MultiItemString  items={ this.state.form.padecimientos.value } 
                                  form_name ={this.state.form.padecimientos.form_name} 
                                  handleRemove = {this.handleRemovePadecimientos}
                                  handleChange={ this.handleChangePadecimientos } />
                
                <div >
                  <button  
                      className="btn btn-success btn-sm"  
                      onClick={ this.addMultiStringItem(this.state.form.padecimientos.form_name) }
                    >Nuevo</button>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <div className='form-group'>
                <label className="caption" htmlFor={'txt_'+this.state.form.medicamentos.form_name}>Medicamentos</label>
                <MultiItemString  items={ this.state.form.medicamentos.value } 
                                  form_name ={this.state.form.medicamentos.form_name} 
                                  handleRemove = {this.handleRemoveMedicamentos}
                                  handleChange={ this.handleChangeMedicamentos } />
                <div >
                  <button  
                      className="btn btn-success btn-sm"  
                      onClick={ this.addMultiStringItem(this.state.form.medicamentos.form_name) }
                    >Nuevo</button>
                </div>
              </div>
            
            </div>
          </div> 

          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div className='form-group'>
                <label className="caption" htmlFor={'txt_'+this.state.form.alergias.form_name}>Alergias</label>
                <MultiItemString  items={ this.state.form.alergias.value } 
                                  form_name ={this.state.form.alergias.form_name} 
                                  handleRemove = {this.handleRemoveAllergies}
                                  handleChange={ this.handleChangeAllergies } />
                
                <div >
                  <button  
                      className="btn btn-success  btn-sm"  
                      onClick={ this.addMultiStringItem(this.state.form.alergias.form_name) }
                    >Nueva</button>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
            </div>
          </div> 


          
          <div className="cmd-holder">
            <a href="#/wizard-perfil" className="btn btn-link" >Cancelar</a>&nbsp;
            <button  className="btn  btn-outline-dark" >Siguiente</button>
          </div>
        </form>
      
          
      );
    }
    }
        
}

export default HealthForm