import React from 'react';
import Title from "../../../componenets/Title/Title";
import ProfileGeneralForm from "../../../componenets/Profile/GeneralForm/GeneralForm";

class StepOne extends React.Component {
    render() {
      return (
        <div>
          <Title  size="3" title="Datos Generales" subtitle="1 de 4" />
          <br></br>
          <ProfileGeneralForm employeeId={this.props.employee.id} employee={this.props.employee} updateMethod={this.props.updateMethod } />
        </div>
      );
    }
  }
  
export default StepOne;