import React from 'react';
import Title from "../../../componenets/Title/Title";
import InterestsForm from "../../../componenets/Profile/InterestForm/InterestForm";


class StepSix extends React.Component {  
    render() {
      return (
        <div>    
          <Title  size="3" title="Otros Eventos" subtitle="4 de 4" />
          <br></br>
          <InterestsForm employeeId={this.props.employee.id} employee={this.props.employee} updateMethod={this.props.updateMethod } />
        </div>
      );
    }
  }

export default StepSix;