import React , {Component} from 'react';
import axios from 'axios';
import Loader from '../../Loader/Loader';
import { Redirect } from "react-router-dom";
import es from 'date-fns/locale/es';

import "react-datepicker/dist/react-datepicker.css";

const USER_SERVICE_URL = process.env.REACT_APP_API_URL + 'employee/';


class ContactForm extends Component {
    constructor(props) {
        super(props);
        /*
        
        "email_personal": {"type":"string"},
        "email_trabajo": {"type":"string"},
        "telefonos":{
        "type":"array",
        "items":{"$ref":"#/definitions/Telefono"}
        },
        "direccion": {
                "type":"object",
                "properties":{
                        "lat":{"type":"string"},
                        "lon":{"type":"string"},
                        "calle y numero":{"type":"string"},
                        "colonia":{"type":"string"},
                        "municipio":{"type":"string"},
                        "estado":{"type":"string"},
                        "cp":{"type":"string"},
                        "pais":{"type":"string"}
                }
        },
        "contacto_emergencia":{
            "type":"array",
            "items":{"$ref":"#/definitions/ContactoEmergencia"}
            },
        */
        this.state = {
          employee: this.props.employee,
          isLoading: true,
          submittedSuccesfully:false,
          isMexican:true,
          form:{
              email_personal: {
                value:'',
                error:false,
                error_message:'escribe un email valido',
                form_name:'email_personal'
              },
              celular: {
                value:'',
                error:false,
                error_message:'escribe un numero valido',
                form_name:'celular'
              },
              contacto_emergencia: {
                value:[],
                error:false,
                error_message:'',
                form_name:'contacto_emergencia'
              }
              
          }
          
        };
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    
      
      componentDidMount() {
        this.preFillFormData();
      }
      handleChangeSelectUnidadOperativa = event =>{
        var {form} = this.state;
        const value = event.value;
        const form_name = "unidad_operativa";
        form[form_name].value = value;
        this.setState({ form: form }, () => {
          this.validate(form_name);
        });
      }
      
      handleChangeSelectDepartamento = event =>{
        var {form} = this.state;
        const value = event.value;
        const form_name = "departamento";
        form[form_name].value = value;
        this.setState({ form: form }, () => {
          this.validate(form_name);
        });
      }

      handleChange = event => {
        var {form} = this.state;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const form_name = target.name;
        form[form_name].value = value;
        this.setState({ form: form }, () => {
          //console.log(this.state.form)
          this.validate(form_name);
        });
      };

      validateHandle = event =>{

      };

      validate(form_name){
        const { form } = this.state;
        const  current_value = form[form_name].value;
        var has_error = false;

        switch(form_name){
          case "apellido_materno":      
              if(current_value === undefined ||  current_value.length < 3){
                has_error = true;                
              }
            break;
          case "segundo_nombre":       
              if(current_value !== undefined && current_value.length > 0){
                if(current_value.length < 3){
                  has_error = true;
                }
              }
            break;          
          
          default:
            break;
        }
        
        form[form_name].error = has_error;
        this.setState({
          form: form
        });
      }

      validateAll(){
        const {form} = this.state;
        for (var key in form){
          this.validate(key);
        }
        return true;
      }

      isFormValid(){
        this.validateAll();
        const {form} = this.state;
        for (var key in form){
          if(form[key].error)
            return false;
        }
        return true;
      }

      getFormJsonData(){
        const {form} = this.state;
        var json_data = {};
        for (var key in form){
          if(key === 'celular'){
            json_data['telefonos']= [{
              etiqueta:'celular',
              telefono:form[key].value,
            }];
          }
          else if( key === 'contacto_emergencia'){

          }
          else{
            if(form[key].value !== undefined && form[key].value !==''){
              json_data['' + form[key].form_name] = form[key].value;
            }else{
              json_data['' + form[key].form_name] = '';
            }
          }
        };
        json_data = JSON.stringify(json_data);
        //console.log(json_data);
        return json_data;
      }

      preFillFormData(){
        const {form, employee} = this.state;
        for (var key in form){
          if(key === 'celular'){
            //var cel = '';
            for ( var phone in employee.telefonos){
              if(employee.telefonos[phone].etiqueta === 'celular'){
                form[key].value = employee.telefonos[phone].telefono;
              }
            }
            //form[key].value = cel;

          }else
          form[key].value = employee[form[key].form_name];
        }
        this.setState({ 
          isLoading: false,
          form: form 
        }, () => {
          
        });
      }

      handleSubmit = event => {
        event.preventDefault();
        if( this.isFormValid() ){
          var json_data = this.getFormJsonData();
          this.setState({
            isLoading: true,
          });
          const th = this;
          var options ={
            method: 'post',
            url:USER_SERVICE_URL+this.props.employeeId,
            data: json_data,
            headers: {
            'Content-Type': 'application/json'
            }
          }
          axios( options).then(async()=> {
            await th.props.updateMethod();
            th.setState({
              isLoading: false,
              submittedSuccesfully:true
            });
          })
          .then(function (response) { 
            //this.props.updateMethod();
            //this.props.history.push('/path')
            
          })
          .catch(function (error) {
            th.setState({
              isLoading: false,
            });
            alert('Error al guardar');
          });
        }else{
          
        }     
      };
    
      render() {
        
        if(this.props.employee.participacion_eventos && this.props.employee.participacion_eventos.convencion2019 === true){
          return <Redirect to="confirmacion" />
        }

        if(this.state.submittedSuccesfully){
          return <Redirect to="salud" />
        }
        if(this.state.isLoading){
          return (
            
          <Loader />
          )
        }else{
          return (
            
                
            <form onSubmit={this.handleSubmit} className="form-profile" >
              <div className="row">
                <div className="col-xs-12">

                </div>
                <div className="col-xs-12">

                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                <div className='form-group'>
            <label className="caption" htmlFor={'txt_'+this.state.form.email_personal.form_name}>Correo Personal</label>
            <input name={this.state.form.email_personal.form_name}
                className={`form-control ${this.state.form.email_personal.error ? 'is-invalid' : ''}`}
                id={'txt_'+this.state.form.email_personal.form_name}
                placeholder=''
                value={this.state.form.email_personal.value}
                onChange={this.handleChange}
                onBlur={this.handleChange} ></input>
            <div className='invalid-feedback'>{this.state.form.email_personal.error_message}</div>
          </div>
                </div>
                <div className="col-xs-12 col-md-6">
                <div className='form-group'>
            <label className="caption" htmlFor={'txt_'+this.state.form.celular.form_name}>Celular</label>
            <input name={this.state.form.celular.form_name}
                className={`form-control ${this.state.form.celular.error ? 'is-invalid' : ''}`}
                id={'txt_'+this.state.form.celular.form_name}
                placeholder='10 digitos'
                value={this.state.form.celular.value}
                onChange={this.handleChange}
                onBlur={this.handleChange} ></input>
            <div className='invalid-feedback'>{this.state.form.celular.error_message}</div>
          </div>
                </div>
              </div>
              
              
                <div className="cmd-holder">
                <a href="#/" className="btn btn-link" >Cancelar</a>&nbsp;
                <button  className="btn btn-outline-dark" >Siguiente</button>
             
              </div>
            </form>
          
             
          );
        }
        }
        
}

export default ContactForm