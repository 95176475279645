import React from 'react';
import Title from "../../../componenets/Title/Title";

class Confirm extends React.Component {
    render() {
      return (
        <div className="row">
          <div className="col-12">
            <Title  size="2" title="Gracias" subtitle="Registro Completado" />
            <br></br><div className="">
              
            <p >Tu registro fue exitoso, recibirás un correo con pasos a seguir. ¡Nos vemos en la Rivera Maya! <span className="caption">#TALISISRocks</span></p>
                
            </div>
                
            <br></br>
            <div className="text-center">
              <a href="http://transformandocomunidades.com"  className="btn  btn-outline-dark" >Ir a Pagina de Evento</a>
            </div>
          </div>
        </div>
      );
    }
  }
export default Confirm;